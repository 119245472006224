@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Poppins", monospace;
}

.swiper {
  width: 100%;
  height: 100%;
  background: #000;
}

.swiper-slide {
  font-size: 18px;
  color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 60px;
}

.parallax-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 130%;
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
}

.swiper-slide .title {
  font-size: 41px;
  font-weight: 300;
}

.swiper-slide .subtitle {
  font-size: 21px;
}

.swiper-slide .text {
  font-size: 14px;
  max-width: 400px;
  line-height: 1.3;
}



/* Change shape default of date icon for following with design figma*/
input[type="date"]::-webkit-calendar-picker-indicator {
  background-image: url('assets/custom-icons/Date.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
  margin-right: 16px;
}

input[type="month"]::-webkit-calendar-picker-indicator {
  background-image: url('assets/custom-icons/Date.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
  margin-right: 16px;
}

.remove-input-txt-border-react-select input[type='text']:focus { 
  box-shadow: none; 
}

.my-select__input, .my-select__input:focus {
  box-shadow: none !important;
  outline: none !important;
}